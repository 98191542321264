<template>
    <v-timeline-item
        fill-dot
        :color="event.driver | driverColour"
        :icon="event.driver | driverIcon"
    >
        <v-row>
            <v-col cols="3">
                <v-tooltip
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >
                            <strong>{{ event.event_at | humanDateTimeOffset }}</strong>
                        </span>
                    </template>
                    <span>{{ event.event_at }}</span>
                </v-tooltip>
            </v-col>
            <v-col
                v-if="event.type == 'RECORDING'"
            >
                <v-row>
                    <v-col cols="10">
                        <div>{{ event.data.output }}</div>
                    </v-col>
                    <v-col class="purple text-center">
                        <v-icon color="white">mdi-file-video-outline</v-icon>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                v-else-if="event.type == 'BEGIN'"
            >
                <v-row>
                    <v-col cols="10">
                        <div>A <a :href="streamUrl"><strong>{{ event.data.type }}</strong></a> stream has started.</div>
                    </v-col>
                    <v-col class="purple text-center">
                        <v-icon color="white">mdi-record</v-icon>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                v-else-if="event.type == 'META'"
            >
                <v-row>
                    <v-col cols="10">
                        <div>{{ event.data.title }} &diams; {{ event.data.game.name }}</div>
                    </v-col>
                    <v-col class="purple text-center">
                        <v-icon color="white">mdi-movie-edit-outline</v-icon>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                v-else-if="event.type == 'END'"
            >
                <v-row>
                    <v-col cols="10">
                        <div>Stream ended.</div>
                    </v-col>
                    <v-col class="purple text-center">
                        <v-icon color="white">mdi-stop</v-icon>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                v-else
            >
                <div>{{ event.data }}</div>
            </v-col>
        </v-row>
    </v-timeline-item>
</template>

<script>
    import { DateTime } from 'luxon'

    export default {
        props: {
            stream: null,
            event: null
        },
        computed: {
            streamUrl: function () {
                return "https://twitch.tv/" + this.stream.name
            }
        },
        filters: {
            humanDateTimeOffset: function (value) {
                let currentTime = new DateTime.now()
                let valueTime = new DateTime.fromISO(value)

                let diffTime = currentTime.diff(valueTime, ['days', 'hours', 'minutes'])

                if (diffTime.days > 1) {
                    return diffTime.toFormat('d') + ' days ago.'
                } else if (diffTime.days == 1) {
                    return diffTime.toFormat('d') + ' day ago.'
                } else if (diffTime.hours > 1) {
                    return diffTime.toFormat('h') + ' hours ago.'
                } else if (diffTime.hours == 1) {
                    return diffTime.toFormat('h') + ' hour ago.'
                } else if (diffTime.minutes > 1) {
                    return diffTime.toFormat('m') + ' minutes ago.'
                } else if (diffTime.minutes == 1) {
                    return diffTime.toFormat('m') + ' minute ago.'
                } else {
                    return 'just now.'
                }

                return diffTime
            },
            driverColour: function (value) {
                if (value == 'TWITCH') {
                    return 'purple'
                } else {
                    return 'blue'
                }
            },
            driverIcon: function (value) {
                if (value == 'TWITCH') {
                    return 'mdi-twitch'
                } else {
                    return 'mdi-cloud-question'
                }
            }
        }
    }
</script>
