<template>
    <v-app id="inspire">
        <v-app-bar
            app
            clipped-left
        >
            <v-btn
                icon
                @click="goToDashboard"
            >
                <v-icon>mdi-av-timer</v-icon>
            </v-btn>
            <v-toolbar-title>Fusr</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu
                bottom
                left
                offset-y
                :close-on-content-click="false"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-icon>mdi-theme-light-dark</v-icon>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch
                                v-model="dark"
                                inset
                            ></v-switch>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-main>
            <router-view></router-view>
        </v-main>

        <v-footer app>
            <span>© {{ new Date().getFullYear() }}</span>
        </v-footer>
    </v-app>
</template>

<script>
    export default {
        props: {
            source: String,
        },
        data: () => ({
            dark: false,
        }),
        watch: {
            dark: function(val) {
                this.$vuetify.theme.dark = val
            }
        },
        methods: {
            goToDashboard() {
                this.$router.push({ name: 'dashboard' })
            }
        },
        created () {
            this.$vuetify.theme.dark = this.dark
        },
    }
</script>
