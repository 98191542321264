<template>
    <v-container>
        <v-card>
            <v-img
                height="250"
                src="/img/logo.jpg"
            >
            </v-img>
            <v-card-text style="position: relative">
                <v-dialog
                    v-model="createDialog"
                    max-width="600px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="primary"
                            fab
                            small
                            absolute
                            top
                            left
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>Create Stream</v-card-title>
                        <v-card-text>
                            <v-text-field
                                v-model="createDialogStreamName"
                                label="Stream Name"
                                required
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary darken-1"
                                text
                                @click="createDialog = false"
                            >
                                Close
                            </v-btn>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="createStream()"
                            >
                                Create
                            </v-btn>
                        </v-card-actions>
                    </v-Card>
                </v-dialog>
            </v-card-text>
            <v-skeleton-loader
                v-if="loading"
                type="list-item-avatar@2"
            ></v-skeleton-loader>
            <v-list
                v-else
                two-line
            >
                <v-list-item
                    v-for="stream in streams"
                    :key="stream.id"
                    @click="goToStream(stream.id)"
                >
                    <v-list-item-avatar>
                        <v-img
                            :src="stream.images.profile"
                        ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title v-text="stream.meta.name"></v-list-item-title>
                        <v-list-item-subtitle v-text="stream.meta.description"></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-chip
                            v-if="stream.is_live"
                            label
                            color="red"
                            text-color="white"
                        >Online</v-chip>
                        <v-chip
                            v-else
                            label
                        >Offline</v-chip>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                streams: null,
                loading: true,
                timer: null,
                createDialog: false,
                createDialogStreamName: ''
            }
        },
        methods: {
            updateData() {
                axios
                    .get('/api/v1/streams')
                    .then(response => {
                        this.streams = response.data.data
                    }).catch(error => {
                        console.log(error)
                    }).finally(() => {
                        this.loading = false
                        this.timer = setTimeout(this.updateData, 10000)
                    })
            },
            goToStream(id) {
                this.$router.push({ name: 'streams', params: { streamId: id }})
            },
            createStream() {
                axios
                    .post('/api/v1/streams', {}, {
                        params: {
                            name: this.createDialogStreamName
                        }
                    })
                    .then(response => {
                        this.updateData()
                    }).catch(error => {
                        console.log(error)
                    }).finally(() => {
                        this.createDialog = false
                    })
            }
        },
        mounted() {
            this.updateData()
        },
        destroyed() {
            clearTimeout(this.timer)
            this.timer = null
        }
    }
</script>
