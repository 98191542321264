<template>
    <v-container>
        <v-skeleton-loader
            v-if="streamLoading"
            type="card, list-item-avatar@3"
        ></v-skeleton-loader>
        <v-card
            v-else
        >
            <v-img
                v-if="headerImageValid"
                height="250"
                :src="stream.images.profile"
            >
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-chip
                        v-if="stream.is_live"
                        label
                        color="red"
                        text-color="white"
                    >Online</v-chip>
                    <v-chip
                        v-else
                        label
                    >Offline</v-chip>
                </v-card-title>
                <v-card-title
                    style="position: absolute; bottom: 0; right: 0"
                >
                    <v-dialog
                        v-model="deleteDialog"
                        max-width="600px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="red"
                                fab
                                small
                            >
                                <v-icon>mdi-delete-forever</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>Delete Stream</v-card-title>
                            <v-card-text>Are you sure?</v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary darken-1"
                                    text
                                    @click="deleteDialog = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="red darken-1"
                                    text
                                    @click="deleteStream()"
                                >
                                    Delete
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
            </v-img>

           <div
                v-else
                :style="headerImageGenerated"
            ></div>

            <v-card-title>{{ stream.meta.name }}</v-card-title>
            <v-card-subtitle>{{ stream.meta.description }}</v-card-subtitle>
            <v-tabs
                v-model="tab"
            >
                <v-tab>Events</v-tab>
                <v-tab>Recordings</v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <v-tabs-items
                v-model="tab"
            >
                <v-tab-item>
                    <v-timeline
                        align-top
                        dense
                    >
                        <timeline-event
                            v-for="event in events"
                            :key="event.id"
                            :event="event"
                            :stream="stream"
                        ></timeline-event>
                    </v-timeline>
                </v-tab-item>
                <v-tab-item>
                    <v-timeline
                        align-top
                        dense
                    >
                        <timeline-event
                            v-for="recording in recordings"
                            :key="recording.id"
                            :event="recording"
                            :stream="stream"
                        ></timeline-event>
                    </v-timeline>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                tab: null,
                stream: null,
                streamLoading: true,
                streamTimer: null,
                events: null,
                eventsLoading: true,
                eventsTimer: null,
                deleteDialog: false
            }
        },
        computed: {
            headerImageGenerated: function () {
                let response
                let svg
                let names

                response = ""

                response = response + "height: 250px;"
                response = response + "background-repeat: repeat;"
                response = response + "background-color: rgb(31, 105, 255);"
                response = response + "background-image: url('data:image/svg+xml;"
                response = response + "base64,"

                names = this.stream.meta.name + " "
                names = names.repeat(5)

                svg = "\
                <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='200px'>\
                    <text x='-150' y='27%' fill='white' font-size='4rem' font-weight='600' opacity='0.1' font-family='Roobert,Helvetica Neue,Helvetica,Arial,sans-serif'>" + names + "</text>\
                    <text x='0' y='60%' fill='white' font-size='4rem' font-weight='600' opacity='0.1' font-family='Roobert,Helvetica Neue,Helvetica,Arial,sans-serif'>" + names + "</text>\
                    <text x='-50' y='93%' fill='white' font-size='4rem' font-weight='600' opacity='0.1' font-family='Roobert,Helvetica Neue,Helvetica,Arial,sans-serif'>" + names + "</text>\
                </svg>\
                "
                response = response + btoa(svg)
                response = response + "');"

                return response
            },
            headerImageValid: function () {
                return (!this.streamLoading && this.stream.images.profile != '')
            },
            recordings: function () {
                if (this.events) {
                    return this.events.filter((event) => {
                        return (event.type == 'RECORDING')
                    })
                } else {
                    return []
                }
            }
        },
        methods: {
            updateDataStream() {
                let streamId

                streamId = this.$route.params.streamId

                axios
                    .get('/api/v1/streams/' + streamId)
                    .then(response => {
                        this.stream = response.data
                    }).catch(error => {
                        console.log(error)
                    }).finally(() => {
                        this.streamLoading = false
                        this.streamTimer = setTimeout(this.updateDataStream, 10000, streamId)
                    })
            },
            updateDataEvents() {
                let streamId

                streamId = this.$route.params.streamId

                axios
                    .get('/api/v1/streams/' + streamId + '/events')
                    .then(response => {
                        this.events = response.data.data
                    }).catch(error => {
                        console.log(error)
                    }).finally(() => {
                        this.eventsLoading = false
                        this.eventsTimer = setTimeout(this.updateDataEvents, 10000, streamId)
                    })
            },
            deleteStream() {
                let streamId

                streamId = this.$route.params.streamId

                axios
                    .delete('/api/v1/streams/' + streamId)
                    .then(response => {
                        this.$router.push({ name: 'dashboard' })
                    }).catch(error => {
                        console.log(error)
                    }).finally(() => {
                        this.deleteDialog = false
                    })
            }
        },
        mounted() {
            this.updateDataStream()
            this.updateDataEvents()
        },
        destroyed() {
            clearTimeout(this.streamTimer)
            this.streamTimer = null
            clearTimeout(this.eventsTimer)
            this.eventsTimer = null
        }
    }
</script>
